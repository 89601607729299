











import { Component, Vue } from 'vue-property-decorator';

import { AuthModule } from '@/store/auth';

Component.registerHooks(['beforeRouteEnter']);

@Component({})
export default class Main extends Vue {
  /**
   * Computed Property
   */

  get isUnauthenticated() {
    return AuthModule.isUnauthenticated;
  }

  get isAuthenticated() {
    return AuthModule.isAuthenticated;
  }

  get isAuthenticanting() {
    return AuthModule.isAuthenticanting;
  }

  /**
   * Lifecycle hooks
   */

  async created() {
    await AuthModule.init();

    if (this.isUnauthenticated && this.$route.path !== '/login') {
      this.$router.push('/login');
    }
  }
}
