import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';

import { IUser } from '@/common/types/user.types';
import router from '@/router/routes';
import api from '@/services/api.service';
import store from '@/store';

import { AuthStatus, IAuthState } from './types';

const MAX_TIME_REFRESH = 900000; // 15min
let TIME_OUT: null | any = null;

@Module({
  dynamic: true,
  namespaced: true,
  name: 'auth',
  store,
})
class Auth extends VuexModule implements IAuthState {
  /**
   * State
   */

  _error?: Error = undefined;
  _status: AuthStatus = AuthStatus.UNAUTHENTICATED;
  _user?: IUser = undefined;
  _token?: string = localStorage.getItem('token') as string;

  /**
   * Getters
   */

  get error(): Error | undefined {
    return this._error;
  }

  get status(): AuthStatus {
    return this._status;
  }

  get user(): IUser | undefined {
    return this._user;
  }

  get token(): string | undefined {
    return this._token;
  }

  get isUnauthenticated() {
    return this._status === AuthStatus.UNAUTHENTICATED;
  }

  get isAuthenticated() {
    return this._status === AuthStatus.AUTHENTICATED;
  }

  get isAuthenticanting() {
    return this._status === AuthStatus.AUTHENTICATING;
  }

  /**
   * Mutations
   */

  @Mutation
  private SET_STATUS(status: AuthStatus) {
    this._status = status;
  }

  @Mutation
  private SET_USER(user: IUser) {
    this._user = user;
  }

  @Mutation
  private SET_ERROR(error: Error) {
    this._error = error;
  }

  @Mutation
  private SET_TOKEN(token: string) {
    this._token = token;
    localStorage.setItem('token', token);
  }

  /**
   * Actions
   */
  @Action
  async init() {
    if (this.token) {
      await this.getMe();
      await this.refreshToken();
    } else {
      this.SET_STATUS(AuthStatus.UNAUTHENTICATED);
    }
  }

  @Action
  async autenticate({ email, password }: { email: string; password: string }) {
    // Verifica se o usuário já não está autenticado;
    if (this.status !== AuthStatus.UNAUTHENTICATED) return;

    const response = await api
      .post(`/auth/sign_in`, {
        email,
        password,
      })
      .then((response) => {
        const token = response.data.jwt;

        this.SET_TOKEN(token);

        this.getMe();
      })
      .catch((err) => {
        console.log(err);

        this.SET_ERROR(err);
        this.SET_STATUS(AuthStatus.UNAUTHENTICATED);

        return this.status;
      });

    return response;
  }

  @Action
  async getMe() {
    if (this.status === AuthStatus.AUTHENTICATING || this.status === AuthStatus.AUTHENTICATED) {
      return;
    } else {
      this.SET_STATUS(AuthStatus.AUTHENTICATING);

      try {
        const response = await api.get(`/auth/me`, {
          headers: { Authorization: `Bearer ${this.token}` },
        });

        this.SET_USER(response.data.data);
        this.SET_STATUS(AuthStatus.AUTHENTICATED);

        return response.data.data;
      } catch (error) {
        this.SET_STATUS(AuthStatus.UNAUTHENTICATED);
        this.SET_ERROR(error);

        router.push('/login');
      }

      this.time();
    }
  }

  @Action
  time() {
    TIME_OUT = setTimeout(() => {
      this.refreshToken();
    }, MAX_TIME_REFRESH);
  }

  @Action
  async refreshToken() {
    try {
      const response = await api.get('/auth/refresh_token', {
        headers: { Authorization: `Bearer ${this.token}` },
      });

      this.SET_TOKEN(response.data.token);
    } catch (error) {
      console.log(error);
    }
    clearTimeout(TIME_OUT);
    this.time();
  }

  @Action
  async getOut() {
    this.SET_STATUS(AuthStatus.UNAUTHENTICATED);
    localStorage.removeItem('token');
    router.push('/login');
  }
}

export const AuthModule = getModule(Auth);
