import Vue from 'vue';
import VueRouter, { RouteConfig, RouterOptions } from 'vue-router';

import Error404 from '@/views/404/404.vue';
import Login from '@/views/login/login.vue';
import Main from '@/views/main/main.vue';

import dashboard from './dashboard.routes';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'Main',
    component: Main,
    children: [dashboard],
    redirect: dashboard,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      authRequires: false,
    },
  },
  {
    path: '*',
    name: '404',
    component: Error404,
    meta: {
      authRequires: true,
    },
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
} as RouterOptions);

export default router;
