



















import { Component, Vue } from 'vue-property-decorator';
import { NavigationGuardNext, Route } from 'vue-router';

import { AuthModule } from '@/store/auth';

Component.registerHooks(['beforeRouteEnter']);

@Component({})
export default class Error404 extends Vue {
  /**
   * Computed Property
   */

  get isUnauthenticated() {
    return AuthModule.isUnauthenticated;
  }

  get isAuthenticated() {
    return AuthModule.isAuthenticated;
  }

  get isAuthenticanting() {
    return AuthModule.isAuthenticanting;
  }

  /**
   * Lifecycle hooks
   */

  created() {
    if (this.isUnauthenticated) {
      this.$router.push('/login');
    }
  }
}
