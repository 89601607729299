import { IUser } from '@/common/types/user.types';

export interface IAuthState {
  _status: AuthStatus;
  _user?: IUser;
  _error?: Error;
}

export enum AuthStatus {
  AUTHENTICATING = 'AUTHENTICATING',
  AUTHENTICATED = 'AUTHENTICATED',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
}
