export function caixaFormaPagamento(value: number) {
  switch (value) {
    case 0:
      return 'Dinheiro';
    case 1:
      return 'Cartão';
    case 2:
      return 'Boleto';
    case 3:
      return 'Depósito';
    case 4:
      return 'Transferência';
    case 5:
      return 'PIX';
    default:
      return '';
  }
}
