import { caixaFormaPagamento } from './caixa-forma-pagamento';
import { caixaTipo } from './caixa-tipo';
import { estadoCivil } from './estado-civil';
import { formatDateTimeBR } from './format-datetime-br';
import { formatPriceBRL } from './money';
import { ordemServicoStatus } from './ordem-servico-status';
import { ordemServicoTipo } from './ordem-servico-tipo';
import { phoneMask } from './phone';

export default {
  caixaFormaPagamento,
  caixaTipo,
  estadoCivil,
  formatPriceBRL,
  phoneMask,
  ordemServicoTipo,
  ordemServicoStatus,
  formatDateTimeBR,
};
