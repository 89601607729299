




































































import { Component, Vue } from 'vue-property-decorator';
import { NavigationGuardNext, Route } from 'vue-router';

import { AuthModule } from '@/store/auth';
import { AuthStatus } from '@/store/auth/types';

Component.registerHooks(['beforeRouteEnter']);
@Component({})
export default class Login extends Vue {
  /**
   * Data
   */

  email: string = '';
  password: string = '';
  remember: boolean = false;

  error: boolean = false;
  errorMessage: string = '';

  /**
   * Computed Propertys
   */

  get isUnauthenticated() {
    return AuthModule.isUnauthenticated;
  }

  get isAuthenticated() {
    return AuthModule.isAuthenticated;
  }

  get isAuthenticanting() {
    return AuthModule.isAuthenticanting;
  }

  /**
   * Lifecycle hooks
   */

  created() {
    if (this.$route.path === '/login' && this.isAuthenticated) {
      this.$router.push('/dashboard');
    }
  }

  /**
   * Methods
   */

  async autenticate() {
    if (this.email.trim() === '' || this.password.trim() === '') {
      this.error = true;
      this.errorMessage = 'Preencha todos os campos';
    } else {
      const response = await AuthModule.autenticate({
        email: this.email,
        password: this.password,
      });

      if (response === AuthStatus.UNAUTHENTICATED) {
        this.error = true;
        this.errorMessage = 'Usuário ou senha inválidos';
      }
    }
  }
}
