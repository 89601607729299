import { RouteConfig } from 'vue-router';
import { component } from 'vue/types/umd';

const dashboard: RouteConfig = {
  path: 'dashboard',
  name: 'Dashboard',
  meta: {
    name: 'Dashboard',
    breadcrumb: 'Dashboard',
    authRequires: true,
  },
  component: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard/dashboard.vue'),
  redirect: 'dashboard/home',
  children: [
    {
      path: 'home',
      name: 'home',
      meta: {
        name: 'Home',
        breadcrumb: 'Home',
        authRequires: true,
      },
      component: () => import(/* webpackChunkName: "home" */ '../views/home/home.vue'),
    },
    {
      path: 'caixa',
      name: 'caixa',
      meta: {
        name: 'Caixa',
        breadcrumb: 'Caixa',
        authRequires: true,
      },
      component: () => import(/* webpackChunkName: "caixa" */ '../views/caixa/caixa.vue'),
    },
    {
      path: 'caixa/cadastro',
      name: 'caixa-cadastro',
      meta: {
        name: 'Lançamento no Caixa',
        breadcrumb: 'Caixa / Lançamento',
        authRequires: true,
      },
      component: () =>
        import(/* webpackChunkName: "cliente.cadastro" */ '../views/caixa/caixa.cadastro.vue'),
    },
    {
      path: 'cliente',
      name: 'cliente',
      meta: {
        name: 'Clientes',
        breadcrumb: 'Clientes',
        authRequires: true,
      },
      component: () => import(/* webpackChunkName: "cliente" */ '../views/cliente/cliente.vue'),
    },
    {
      path: 'cliente/cadastro',
      name: 'cliente-cadastro',
      meta: {
        name: 'Cadastro de clientes',
        breadcrumb: 'Clientes / Cadastro',
        authRequires: true,
      },
      component: () =>
        import(/* webpackChunkName: "cliente.cadastro" */ '../views/cliente/cliente.cadastro.vue'),
    },
    {
      path: 'parceiros',
      name: 'parceiros',
      meta: {
        name: 'Parceiros',
        breadcrumb: 'Parceiros',
        authRequires: true,
      },
      component: () => import(/* webpackChunkName: "parceiros" */ '../views/parceiro/parceiro.vue'),
    },
    {
      path: 'parceiros/cadastro',
      name: 'parceiros-cadastro',
      meta: {
        name: 'Cadastro de parceiros',
        breadcrumb: 'Parceiros / Cadastro',
        authRequires: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "parceiros.cadastro" */ '../views/parceiro/parceiro.cadastro.vue'
        ),
    },
    {
      path: 'tipos-veiculos',
      name: 'tipos-veiculos',
      meta: {
        name: 'Tipos veículos',
        breadcrumb: 'Tipos veículos',
        authRequires: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "tipos-veiculos" */ '../views/tipos-veiculo/tipos-veiculos.vue'
        ),
    },
    {
      path: 'tipos-veiculos/cadastro',
      name: 'tipos-veiculos-cadastro',
      meta: {
        name: 'Cadastro de tipos de veículo',
        breadcrumb: 'Tipos de Veículo / Cadastro',
        authRequires: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "tipos-veiculos.cadastro" */ '../views/tipos-veiculo/tipo-veiculo.cadastro.vue'
        ),
    },
    {
      path: 'ordem-servico',
      name: 'ordem-servico',
      meta: {
        name: 'Ordem de serviço',
        breadcrumb: 'Ordem de serviço',
        authRequires: true,
      },
      component: () =>
        import(/* webpackChunkName: "ordem.servico" */ '../views/ordem-servicos/ordem.list.vue'),
    },
    {
      path: 'ordem-servico/view/:id',
      name: 'ordem-servico-view',
      meta: {
        name: 'Ordem de serviço',
        breadcrumb: 'Ordem de serviço',
        authRequires: true,
      },
      component: () =>
        import(/* webpackChunkName: "ordem.servico" */ '../views/ordem-servicos/ordem.view.vue'),
    },
    // {
    //   path: 'ordem-servico/cadastro/primeiro-veiculo',
    //   name: 'ordem-servico-primeiro-veiculo',
    //   meta: {
    //     name: 'Ordem de serviço',
    //     breadcrumb: 'Ordem de serviço / Cadastro',
    //     authRequires: true,
    //   },
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "ordem.servico" */ '../views/ordem-servicos/ordem.cadastro-primeiro-veiculo.vue'
    //     ),
    // },
    {
      path: 'ordem-servico/cadastro/geral',
      name: 'ordem-servico-cadastro-geral',
      meta: {
        name: 'Ordem de serviço',
        breadcrumb: 'Ordem de serviço / Cadastro',
        authRequires: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "ordem.servico" */ '../views/ordem-servicos/ordem.cadastro.geral.vue'
        ),
    },
    {
      path: 'ordem-servico/relatorio/totais',
      name: 'ordem-servico-relatorio-totais',
      meta: {
        name: 'Relatório - Ordem de Serviço',
        breadcrumb: 'Relatório / Faturamento',
        authRequires: true,
      },
      component: () => import('../views/ordem-servicos/ordem.relatorio-totais.vue'),
    },
    {
      path: 'ordem-servico/relatorio/sem-valor-pago',
      name: 'ordem-servico-relatorio-sem-valor-pago',
      meta: {
        name: 'Relatório - Ordem de Serviço',
        breadcrumb: 'Relatório / Processos sem valor pago',
        authRequires: true,
      },
      component: () => import('../views/ordem-servicos/ordem.relatorio-sem-valor-pago.vue'),
    },
    {
      path: 'ordem-servico/relatorio/com-valor-receber',
      name: 'ordem-servico-relatorio-com-valor-receber',
      meta: {
        name: 'Relatório - Ordem de Serviço',
        breadcrumb: 'Relatório / Processos com valores a receber',
        authRequires: true,
      },
      component: () => import('../views/ordem-servicos/ordem.relatorio-com-valor-receber.vue'),
    },
    // {
    //   path: 'ordem-servico/cadastro/simples',
    //   name: 'ordem-servico-simples',
    //   meta: {
    //     name: 'Ordem de serviço',
    //     breadcrumb: 'Ordem de serviço / Cadastro',
    //     authRequires: true,
    //   },
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "ordem.servico" */ '../views/ordem-servicos/ordem.cadastro-simples.vue'
    //     ),
    // },
    // {
    //   path: 'ordem-servico/cadastro/veiculo-usado',
    //   name: 'ordem-servico-veiculo-usado',
    //   meta: {
    //     name: 'Ordem de serviço',
    //     breadcrumb: 'Ordem de serviço / Cadastro',
    //     authRequires: true,
    //   },
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "ordem.servico" */ '../views/ordem-servicos/ordem.cadastro-veiculo-usado.vue'
    //     ),
    // },
    {
      path: 'servico',
      name: 'Serviços',
      meta: {
        name: 'Serviços',
        breadcrumb: 'Serviços',
        authRequires: true,
      },
      component: () => import(/* webpackChunkName: "servico" */ '../views/servico/servico.vue'),
    },
    {
      path: 'servico/cadastro',
      name: 'Serviço-Cadastro',
      meta: {
        name: 'Cadastro de serviços',
        breadcrumb: 'Serviços / Cadastro',
        authRequires: true,
      },
      component: () =>
        import(/* webpackChunkName: "servico.cadastro" */ '../views/servico/servico.cadastro.vue'),
    },
    {
      path: 'tipo-contrato',
      name: 'Tipos de Contrato',
      meta: {
        name: 'Tipos de contrato',
        breadcrumb: 'Tipos de contrato',
        authRequires: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "tipos-contrato" */ '../views/tipo-contrato/tipo-contrato.list.vue'
        ),
    },
    {
      path: 'tipo-contrato/cadastro',
      name: 'Tipos de Contrato-Cadastro',
      meta: {
        name: 'Cadastro de tipos de contrato',
        breadcrumb: 'Tipos de contrato / Cadastro',
        authRequires: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "tipos-contrato.cadastro" */ '../views/tipo-contrato/tipo-contrato.form.vue'
        ),
    },
    {
      path: 'contrato',
      name: 'Contratos',
      meta: {
        name: 'Contratos',
        breadcrumb: 'Contratos',
        authRequires: true,
      },
      component: () =>
        import(/* webpackChunkName: "contratos" */ '../views/contrato/contrato.list.vue'),
    },
    {
      path: 'contrato/cadastro',
      name: 'Contratos-Cadastro',
      meta: {
        name: 'Cadastro de contratos',
        breadcrumb: 'Contratos / Cadastro',
        authRequires: true,
      },
      component: () =>
        import(/* webpackChunkName: "contratos.cadastro" */ '../views/contrato/contrato.form.vue'),
    },
    {
      path: 'usuario',
      name: 'Usuários',
      meta: {
        name: 'Usuários',
        breadcrumb: 'Usuários',
        authRequires: true,
      },
      component: () =>
        import(/* webpackChunkName: "usuarios" */ '../views/usuario/usuario.list.vue'),
    },
    {
      path: 'usuario/cadastro',
      name: 'Usuários-Cadastro',
      meta: {
        name: 'Cadastro de Usuários',
        breadcrumb: 'Usuários / Cadastro',
        authRequires: true,
      },
      component: () =>
        import(/* webpackChunkName: "usuarios.cadastro" */ '../views/usuario/usuario.form.vue'),
    },
    {
      path: 'role',
      name: 'Permissões',
      meta: {
        name: 'Permissões',
        breadcrumb: 'Permissões',
        authRequires: true,
      },
      component: () => import(/* webpackChunkName: "permissoes" */ '../views/role/role.list.vue'),
    },
    {
      path: 'role/cadastro',
      name: 'Permissões-Cadastro',
      meta: {
        name: 'Cadastro de Permissões',
        breadcrumb: 'Permissões / Cadastro',
        authRequires: true,
      },
      component: () =>
        import(/* webpackChunkName: "permissoes.cadastro" */ '../views/role/role.form.vue'),
    },
    {
      path: 'veiculo',
      name: 'Veículos',
      meta: {
        name: 'Veículos',
        breadcrumb: 'Veículos',
        authRequires: true,
      },
      component: () =>
        import(/* webpackChunkName: "veiculos" */ '../views/veiculo/veiculo.list.vue'),
    },
    {
      path: 'veiculo/cadastro',
      name: 'Veículos-Cadastro',
      meta: {
        name: 'Cadastro de Veículos',
        breadcrumb: 'Veículos / Cadastro',
        authRequires: true,
      },
      component: () =>
        import(/* webpackChunkName: "veiculos.cadastro" */ '../views/veiculo/veiculo.form.vue'),
    },
    {
      path: 'profile',
      name: 'Perfil',
      meta: {
        name: 'Perfil',
        breadcrumb: 'Perfil',
        authRequires: true,
      },
      component: () =>
        import(/* webpackChunkName: "veiculos.cadastro" */ '../views/profile/profile.vue'),
    },
  ],
};

export default dashboard;
