import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/vue';
import { BootstrapVue } from 'bootstrap-vue';
import moment from 'moment-timezone';
import vClickOutside from 'v-click-outside';
//@ts-ignore
import money from 'v-money';
import Vue from 'vue';
import VueBrazil from 'vue-brazil';
import VModal from 'vue-js-modal';
import VueSweetalert2 from 'vue-sweetalert2';
import VueTheMask from 'vue-the-mask';
import Vuelidate from 'vuelidate';

import filters from '@/common/filters';

import router from './router/routes';
import store from './store';
import Main from './views/main/main.vue';

import 'hint.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue2-datepicker/locale/pt-br';
const packageJson = require('../package.json');

// Configura o idioma do moment para português
require('moment/locale/pt-br');

Vue.use(BootstrapVue);
Vue.use(VueBrazil);
Vue.use(VueTheMask);
Vue.use(VModal);
Vue.use(VueSweetalert2);
Vue.use(require('v-click-outside'), { vClickOutside });
Vue.use(require('vue-moment'), { moment });
Vue.use(Vuelidate);
Vue.use(money, {
  decimal: ',',
  thousands: '.',
  prefix: 'R$ ',
  precision: 2,
  masked: false,
});

Vue.directive('uppercase', {
  update: function(el: any) {
    el.value = el.value.toUpperCase();
  },
});

Vue.directive('focus', {
  inserted: function(el: any) {
    el.focus();
  },
});

Vue.mixin({
  filters,
});

// Sentry.init({
//   Vue,
//   dsn: 'https://1024fe7cafd54b46b2657931193b5c15@o589381.ingest.sentry.io/5739516',
//   integrations: [new Integrations.BrowserTracing()],

//   release: `despachante-manager-${packageJson.version}`,
//   environment: process.env.NODE_ENV === 'production' ? 'production' : 'development',
//   tracesSampleRate: 1.0,
// });

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(Main),
}).$mount('#app');
