const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

export function phoneMask(phone: number | any) {
  if (!phone) return;

  const parsePhoneNumber = phoneUtil.parseAndKeepRawInput(phone, 'BR');

  const phoneNumber = phoneUtil.formatInOriginalFormat(parsePhoneNumber);

  return phoneNumber;
}
