import axios from 'axios';

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? 'https://gestaodoc.net/api'
      : 'http://localhost:4000/api',
});

export default api;
