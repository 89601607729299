export function estadoCivil(value: number) {
  switch (value) {
    case 0:
      return 'SOLTERIO(A)';

    case 1:
      return 'CASADO(A)';

    case 2:
      return 'VIÚVO(A)';

    case 3:
      return 'DIVORCIADO(A)';
  }
}
