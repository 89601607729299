export function ordemServicoStatus(value: number) {
  switch (value) {
    case 0:
      return 'AGUARDANDO APROVAÇÃO';
    case 1:
      return 'EM ANDAMENTO';
    case 2:
      return 'EMITIDO';
    case 3:
      return 'PROTOCOLADO';
    case 4:
      return 'PENDENTE';
    case 6:
      return 'ENTREGUE';
    case 7:
      return 'AGUARDANDO VISTORIA';
    case 8:
      return 'VISTORIA AGENDADA';
  }
}
