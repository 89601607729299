export function ordemServicoTipo(value: number) {
  switch (value) {
    case 0:
      return '1° EMPLACAMENTO';
    case 1:
      return 'VEÍCULO USADO';
    case 2:
      return 'SIMPLES';
    case 3:
      return 'MUDANÇA DE UF';
    case 4:
      return 'CNH';
    case 5:
      return 'ANTT/LICENÇAS DIVERSAS';
  }
}
